import 'react-loading-skeleton/dist/skeleton.css'

import { SkeletonTheme } from 'react-loading-skeleton'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useReverseProxyProtection } from 'hooks/useReverseProxyProtection'
import styled from 'styled-components'

import type { ISiteConfigFields } from 'contentful-shared'
import { DevToolsGridOverlay } from 'bl-common/src/units/DevToolsGridOverlay'
import { EscapeStackProvider } from 'bl-common/src/units/EscapeStack'

import { getCurrentEnvironment } from 'utils/environment'

import { CookieConsentStyles } from './CookieConsentStyles'
import InformationBanner from './InformationBanner'
import { LoadTracking } from './LoadTracking'
import { Notifications } from './Notifications'
import { PageManager } from './PageManager'

const environment = getCurrentEnvironment()

type LayoutProps = {
  alertBanners: ISiteConfigFields['alertBanners']
  bannerColor: ISiteConfigFields['bannerColor']
  notifications: ISiteConfigFields['notifications']
  children: React.ReactNode
}

const Container = styled.div`
  position: relative;
`

// Filter banners based on visibility route settings
const filterBanners = (
  alertBanners: ISiteConfigFields['alertBanners'],
  route: string
) => {
  // Hotel booking engine visibility
  if (
    route.startsWith('/book/accommodation') ||
    route.startsWith('/accommodation/inroom/book') ||
    route.startsWith('/is/boka/gisting') ||
    route.startsWith('/book/package')
  ) {
    return alertBanners.filter(banner => banner.fields.showInHotelBookingEngine)
  }
  // Flexible spa booking engine visibility
  if (route.startsWith('/book/spa') || route.startsWith('/is/boka/spa')) {
    return alertBanners.filter(
      banner => banner.fields.showInFlexibleSpaBookingEngine
    )
  }
  // Spa booking engine visibility
  if (route.startsWith('/book') || route.startsWith('/is/boka')) {
    return alertBanners.filter(banner => banner.fields.showInBookingEngine)
  }
  // Default: Website visibility
  return alertBanners.filter(banner => banner.fields.showOnWebsite)
}

export const Layout = ({
  alertBanners,
  bannerColor,
  notifications,
  children,
}: LayoutProps) => {
  useReverseProxyProtection()
  const router = useRouter()
  const filteredBanners = alertBanners
    ? filterBanners(alertBanners, router.asPath)
    : []

  return (
    <SkeletonTheme
      borderRadius={0}
      baseColor="var(--color-skeletonBase)"
      highlightColor="var(--color-skeletonHighlight)"
    >
      <LoadTracking />
      <EscapeStackProvider>
        <Container>
          <CookieConsentStyles />
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="mobile-web-app-capable" content="yes" />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            {environment === 'development' ? (
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32-dev.png"
              />
            ) : (
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
            )}
            <link rel="mask-icon" href="/safari-pinned-tab" color="#51a7ba" />
            <meta name="msapplication-TileColor" content="#51a7ba" />
            <meta name="theme-color" content="#ffffff" />
            <title key="title">Blue Lagoon Iceland</title>
            <link
              rel="preconnect"
              href="//cdn.contentful.com"
              crossOrigin="anonymous"
            />
            <link rel="preconnect" href="//images.ctfassets.net" />
            <link rel="preconnect" href="//assets.ctfassets.net" />
            <link rel="preconnect" href="//websiteresourcess3.azureedge.net" />
            <link rel="preconnect" href="//cookie.consent.is" />
          </Head>

          {/* We want to render this on all environments to prevent hidden layout bugs */}
          <InformationBanner banners={filteredBanners} color={bannerColor} />
          <Notifications notifications={notifications} />
          <PageManager>{children}</PageManager>
        </Container>
        {process.env.NEXT_PUBLIC_SHOW_DEVTOOLS && <DevToolsGridOverlay />}
      </EscapeStackProvider>
    </SkeletonTheme>
  )
}
